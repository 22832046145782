import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "columns"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SNotification = _resolveComponent("SNotification")!
  const _component_SSpinner = _resolveComponent("SSpinner")!
  const _component_CSurveyMainInfo = _resolveComponent("CSurveyMainInfo")!
  const _component_CSurveyTestsList = _resolveComponent("CSurveyTestsList")!
  const _component_CView = _resolveComponent("CView")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
  }, [
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_SNotification, {
          key: 0,
          "button-text": "Go to surveys list",
          title: `${_ctx.error.status}: ${_ctx.error.statusText}`,
          "on-button-click": _ctx.pushToSurveysList,
          centered: "",
          danger: ""
        }, null, 8, ["title", "on-button-click"]))
      : (_openBlock(), _createBlock(_component_CView, {
          key: 1,
          title: "Survey",
          "sub-title": `${_ctx.surveyId ? 'Edit' : 'Create new'} survey`,
          "button-props": {
				class: _ctx.isProcessing && 'is-loading',
				buttonText: _ctx.surveyId ? 'Save' : 'Create',
				color: 'link',
				type: 'submit',
				iconClass: 'bi bi-clipboard-check',
				disabled: _ctx.isProcessing,
				onClick: _ctx.surveyId ? _ctx.onEditSurvey : _ctx.onCreateSurvey
			},
          "button-warning": _ctx.hasUnsavedMainChanges || _ctx.hasUnsavedTestsChanges ? 'There are unsaved changes' : null
        }, {
          default: _withCtx(() => [
            (_ctx.isProcessing)
              ? (_openBlock(), _createBlock(_component_SSpinner, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_CSurveyMainInfo, {
                    ref: "mainInfoRef",
                    "fetched-survey": _ctx.fetchedSurvey,
                    "fetched-tags-list": _ctx.fetchedTagsList,
                    "fetched-skills-list": _ctx.fetchedSkillsList
                  }, null, 8, ["fetched-survey", "fetched-tags-list", "fetched-skills-list"]),
                  _createVNode(_component_CSurveyTestsList, {
                    ref: "testsListRef",
                    "fetched-tests-list": _ctx.fetchedSurvey?.testsList
                  }, null, 8, ["fetched-tests-list"])
                ]))
          ]),
          _: 1
        }, 8, ["sub-title", "button-props", "button-warning"]))
  ], 32))
}