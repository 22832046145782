import CSurveyTest from '@/components/CSurveyTest/CSurveyTest.vue';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { IInitialState, ITest } from './CSurveyTestsList.types';

export default defineComponent({
	name: 'CSurveyTestsList',
	components: {
		CSurveyTest
	},
	props: {
		fetchedTestsList: {
			type: Object as PropType<IInitialState['testsList'] | null>,
			default: null
		}
	},
	data(): IInitialState {
		return {
			testsList: [],
			selectedTest: null,
			showCreateTestModal: false
		};
	},
	created() {
		if (this.fetchedTestsList) {
			this.testsList = [...this.fetchedTestsList];
		}
	},
	methods: {
		onCreateTestButtonClick() {
			this.selectedTest = null;

			this.showTestModal();
		},
		openTestModal(selectedTest: IInitialState['selectedTest']) {
			this.selectedTest = selectedTest;

			this.showTestModal();
		},
		showTestModal() {
			this.showCreateTestModal = true;
		},
		hideTestModal() {
			this.showCreateTestModal = false;
		},
		getTestControlButtons(surveyTest: ITest) {
			return [{
				iconClass: 'bi bi-trash',
				onClick: () => this.deleteSurveyTest(surveyTest)
			}];
		},
		onSaveSurveyTest(surveyTest: ITest) {
			this.saveSurveyTest(surveyTest);

			this.hideTestModal();
		},
		deleteSurveyTest(surveyTest: ITest) {
			const deleteSurveyIndex = this.testsList.findIndex(e => e.id === surveyTest.id);

			if (deleteSurveyIndex !== -1) {
				this.testsList.splice(deleteSurveyIndex, 1);
			}
		},
		saveSurveyTest(surveyTest: ITest) {
			const surveyIndex = this.testsList.findIndex(e => e.id === surveyTest.id);

			if (surveyIndex === -1) {
				this.testsList.push(surveyTest);
			} else {
				this.testsList.splice(surveyIndex, 1, surveyTest);
			}
		}
	}
});
