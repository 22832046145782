import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12bbc2b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { class: "is-flex is-align-items-center" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SField = _resolveComponent("SField")!
  const _component_STextarea = _resolveComponent("STextarea")!
  const _component_STabs = _resolveComponent("STabs")!
  const _component_SNotification = _resolveComponent("SNotification")!
  const _component_SCodeEditor = _resolveComponent("SCodeEditor")!
  const _component_SCreateButton = _resolveComponent("SCreateButton")!
  const _component_SSwitch = _resolveComponent("SSwitch")!
  const _component_SButton = _resolveComponent("SButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "section p-0",
    onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.onSubmit(_ctx.test)), ["prevent"]))
  }, [
    _createVNode(_component_SField, {
      modelValue: _ctx.test.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.test.name) = $event)),
      label: "Name",
      name: "testName",
      maxlength: "50",
      class: "is-small",
      placeholder: "e.g Valid Parentheses",
      required: ""
    }, null, 8, ["modelValue"]),
    _createVNode(_component_STextarea, {
      modelValue: _ctx.test.description,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.test.description) = $event)),
      class: "is-small",
      placeholder: "e.g Write a function that takes a string of parentheses, and determines if the order of the parentheses is valid. The function should return true if the string is valid, and false if it's invalid.",
      label: "Description",
      required: ""
    }, null, 8, ["modelValue"]),
    _createVNode(_component_STabs, {
      modelValue: _ctx.test.answer.type,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.test.answer.type) = $event)),
      label: "Answer type",
      "tabs-list": _ctx.answerTypesList,
      "validate-tab": _ctx.validateTab
    }, null, 8, ["modelValue", "tabs-list", "validate-tab"]),
    (_ctx.showChangeTabWarning)
      ? (_openBlock(), _createBlock(_component_SNotification, {
          key: 0,
          class: "mb-3",
          title: "Changes will not saved. Continue?",
          "button-text": "Continue",
          "on-button-click": _ctx.onConfirmChangeTab,
          warning: ""
        }, null, 8, ["on-button-click"]))
      : _createCommentVNode("", true),
    (_ctx.showTextAnswer())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_STextarea, {
            modelValue: _ctx.test.answer.correctText,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.test.answer.correctText) = $event)),
            label: "Correct answer",
            name: "correctAnswer",
            class: "is-small",
            placeholder: "e.g ?? is a logical operator that returns the value of the right operand when the value of the left operand is null or undefined",
            required: ""
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showCodeAnswer())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SCodeEditor, {
            modelValue: _ctx.test.answer.correctCode,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.test.answer.correctCode) = $event))
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showRadioAnswer())
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_SCreateButton, {
            "button-text": "Add radio",
            onClick: _ctx.addRadio
          }, null, 8, ["onClick"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.test.answer.radiosList, (radio) => {
            return (_openBlock(), _createElementBlock("div", {
              key: radio.id,
              class: "radio-field mt-3"
            }, [
              _createVNode(_component_SField, {
                modelValue: radio.value,
                "onUpdate:modelValue": ($event: any) => ((radio.value) = $event),
                name: "radioText",
                maxlength: "50",
                class: "is-small",
                placeholder: "Radio text"
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_SSwitch, {
                  id: radio.id,
                  modelValue: radio.isCorrect,
                  "onUpdate:modelValue": ($event: any) => ((radio.isCorrect) = $event),
                  label: "Is correct"
                }, null, 8, ["id", "modelValue", "onUpdate:modelValue"]),
                _createElementVNode("div", {
                  class: "tag is-delete is-clickable ml-3",
                  onClick: ($event: any) => (_ctx.deleteRadio(radio.id))
                }, null, 8, _hoisted_5)
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_SButton, {
      "button-text": _ctx.selectedTest ? 'Save' : 'Create test',
      class: "mt-4",
      type: "submit",
      color: "link"
    }, null, 8, ["button-text"])
  ], 32))
}