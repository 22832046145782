import CSurveyTestsList from '@/components/CSurveyTestsList/CSurveyTestsList.vue';
import CSurveyMainInfo from '@/components/CSurveyMainInfo/CSurveyMainInfo.vue';
import CView from '@/components/CView/CView.vue';
import { ESurveysActionType, ETagsActionType } from '@/store';
import { ERouteNames } from '@/router';
import { useRoute } from 'vue-router';
import { defineComponent, ref } from 'vue';
import type { IInitialState, TSurveyData } from './SurveyView.types';

export default defineComponent({
	name: 'SurveyView',
	components: {
		CSurveyTestsList,
		CSurveyMainInfo,
		CView
	},
	beforeRouteLeave(to, from, next) {
		if (this.isProcessing || (!this.hasUnsavedMainChanges && !this.hasUnsavedTestsChanges)) {
			next();

			return;
		}

		const isConfirmLeave = confirm('There are changes that are not saved. Continue?');

		if (isConfirmLeave) {
			next();
		}
	},
	setup() {
		const route = useRoute();
		const testsListRef = ref<typeof CSurveyTestsList>();
		const mainInfoRef = ref<typeof CSurveyMainInfo>();

		return {
			testsListRef,
			mainInfoRef,
			surveyId: Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
		};
	},
	data(): IInitialState {
		return {
			isProcessing: false,
			hasUnsavedMainChanges: null,
			hasUnsavedTestsChanges: null,
			error: null
		};
	},
	computed: {
		fetchedSurvey() {
			if (this.surveyId) {
				return this.$store.state.surveysStore.survey;
			}

			return null;
		},
		fetchedTagsList() {
			return this.$store.state.tagsStore.tags?.common || [];
		},
		fetchedSkillsList() {
			return this.$store.state.tagsStore.tags?.skills || [];
		}
	},
	watch: {
		'mainInfoRef.survey': {
			handler() {
				this.hasUnsavedMainChanges = this.fetchedSurvey && this.hasUnsavedMainChanges !== null;
			},
			deep: true
		},
		'testsListRef.testsList': {
			handler() {
				this.hasUnsavedTestsChanges = this.fetchedSurvey && this.hasUnsavedTestsChanges !== null;
			},
			deep: true
		}
	},
	async created() {
		this.startProcessing();

		Promise.all([
			this.fetchSurvey(),
			this.fetchTags()
		])
			.catch(this.setErrorMessage)
			.finally(this.stopProcessing);
	},
	methods: {
		onCreateSurvey() {
			this.sendSurveyRequest(ESurveysActionType.CREATE_SURVEY);
		},
		onEditSurvey() {
			this.sendSurveyRequest(ESurveysActionType.EDIT_SURVEY);
		},
		sendSurveyRequest(actionType: ESurveysActionType.CREATE_SURVEY | ESurveysActionType.EDIT_SURVEY) {
			const survey = this.getSurveyData();

			if (!this.formIsValid(survey)) {
				return;
			}

			this.startProcessing();

			return this.$store.dispatch(actionType, { data: survey })
				.then(this.pushToSurveysList)
				.catch(this.setErrorMessage)
				.finally(this.stopProcessing);
		},
		setErrorMessage({ response }: { response: Response }) {
			this.error = {
				status: response.status,
				statusText: response.statusText
			};
		},
		formIsValid(survey: TSurveyData) {
			return survey.name && survey.description;
		},
		pushToSurveysList() {
			this.$router.push({ name: ERouteNames.Surveys });
		},
		startProcessing() {
			this.isProcessing = true;
		},
		stopProcessing() {
			this.isProcessing = false;
		},
		fetchSurvey() {
			if (this.surveyId) {
				return this.$store.dispatch(ESurveysActionType.GET_SURVEY, { surveyId: this.surveyId });
			}
		},
		fetchTags() {
			return this.$store.dispatch(ETagsActionType.GET_TAGS);
		},
		getSurveyData(): TSurveyData {
			const mainInfo: Omit<TSurveyData, 'testsList'> = this.mainInfoRef?.survey;
			const testsList: TSurveyData['testsList'] = this.testsListRef?.testsList;

			return {
				...mainInfo,
				testsList: [...(testsList || [])]
			};
		}
	}
});
