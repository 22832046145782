import { v4 } from 'uuid';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { IAnswer, IInitialState, IRadio, TAnswerType, TOnSubmit } from './CSurveyTest.types';

const getInitialAnswer = (): IAnswer => ({
	type: 'Text',
	correctText: '',
	correctCode: '/* Type correct code here */ \n',
	radiosList: []
});

const getInitialState = (): IInitialState => ({
	test: {
		name: '',
		description: '',
		answer: getInitialAnswer(),
		id: v4()
	},
	answerTypesList: ['Text', 'Code', 'Radio'],
	showChangeTabWarning: false,
	nextTabName: null
});

export default defineComponent({
	name: 'CSurveyTest',
	props: {
		onSubmit: {
			type: Function as PropType<TOnSubmit>,
			required: true
		},
		selectedTest: {
			type: Object as PropType<IInitialState['test']>,
			default: undefined
		}
	},
	data(): IInitialState {
		return getInitialState();
	},
	created() {
		this.applySelectedTest();
	},
	methods: {
		showTextAnswer() {
			return this.test.answer?.type === 'Text';
		},
		showCodeAnswer() {
			return this.test.answer?.type === 'Code';
		},
		showRadioAnswer() {
			return this.test.answer?.type === 'Radio';
		},
		showTabWarning() {
			this.showChangeTabWarning = true;
		},
		hideTabWarning() {
			this.showChangeTabWarning = false;
		},
		addRadio() {
			this.test.answer.radiosList.push({
				value: '',
				isCorrect: false,
				id: v4()
			});
		},
		deleteRadio(deletedRadioId: IRadio['id']) {
			const newRadiosList = this.test.answer.radiosList.filter(radio => radio.id !== deletedRadioId);

			this.test.answer.radiosList = newRadiosList;
		},
		validateTab(nextTabName: TAnswerType) {
			const isTabNotContainUnsavedChanges = this.tabDoesNotContainUnsavedChanges(nextTabName);

			if (!isTabNotContainUnsavedChanges) {
				this.nextTabName = nextTabName;
				this.showTabWarning();
			}

			return isTabNotContainUnsavedChanges;
		},
		tabDoesNotContainUnsavedChanges(nextTabName: TAnswerType) {
			if (nextTabName !== this.test.answer.type) {
				const { correctText, correctCode, radiosList } = this.test.answer;
				const initialAnswer = getInitialAnswer();
				const isCorrectTextNotChanged = correctText === initialAnswer.correctText;
				const isCorrectCodeNotChanged = correctCode === initialAnswer.correctCode;
				const isRadiosListNotChanged = radiosList.length === initialAnswer.radiosList.length;

				switch (nextTabName) {
					case 'Text':
						return isCorrectCodeNotChanged && isRadiosListNotChanged;
					case 'Code':
						return isCorrectTextNotChanged && isRadiosListNotChanged;
					case 'Radio':
						return isCorrectTextNotChanged && isCorrectCodeNotChanged;
				}
			}

			return true;
		},
		onConfirmChangeTab() {
			this.hideTabWarning();
			this.goToNextTab();
			this.clearAnswerData();
		},
		goToNextTab() {
			if (this.nextTabName) {
				this.test.answer.type = this.nextTabName;
			}

			this.nextTabName = null;
		},
		clearAnswerData() {
			const currentAnswerType = this.test.answer.type;

			this.test.answer = {
				...getInitialAnswer(),
				type: currentAnswerType
			};
		},
		applySelectedTest() {
			if (this.selectedTest) {
				Object.assign(this.test, this.selectedTest);
			} else {
				this.resetState();
			}
		},
		resetState() {
			Object.assign(this.$data, getInitialState());
		}
	}
});
