import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { IInitialState } from './CSurveyMainInfo.types';

export default defineComponent({
	name: 'CSurveyMainInfo',
	props: {
		fetchedSurvey: {
			type: Object as PropType<IInitialState['survey'] | null>,
			default: null
		},
		fetchedTagsList: {
			type: Array as PropType<IInitialState['tagsList'] | null>,
			default: null
		},
		fetchedSkillsList: {
			type: Array as PropType<IInitialState['skillsList'] | null>,
			default: null
		}
	},
	data(): IInitialState {
		return {
			survey: {
				name: '',
				description: '',
				tagsList: [],
				skillsList: [],
				time: null
			},
			tagsList: [],
			skillsList: []
		};
	},
	created() {
		if (this.fetchedSurvey) {
			this.survey = { ...this.fetchedSurvey };
		}

		if (this.fetchedTagsList) {
			this.tagsList = [...this.fetchedTagsList];
		}

		if (this.fetchedSkillsList) {
			this.skillsList = [...this.fetchedSkillsList];
		}
	}
});
